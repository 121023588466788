import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateState } from "../redux/commonSlice";
//import { setSnackbar } from "../redux/messageSlice";
import {
  checkVerificationToken,
  forgotPassword,
  resetPassword,
  userSignin,
  userSignup,
  verifyUserMail,
  resendLink
} from "../services/api";
import { setSnackMessageType } from "../utils/commonServices";
import useEventList from "./useEventList";
import useSnackMessage from "./useSnackMessage";
import { gaEvents } from "../ga/actions";
import { updatePermissionState } from "../redux/permissionSlice";
import { getPermissions, getUserRoles } from "../commonactions/actions";
import { setAssetState } from "../redux/assetSlice";
import { getTheme } from "../pages/whitelabel/actions";
import { updateAppleSignState } from "../redux/appleSignSlice";
import { updatePendingCallState } from "../redux/pendingCallSlice";
import { encryptString } from "../utils/encryptionServices";

export default (requestType, setSnakeBarProps, forgotPasswordCallback) => {
  const [checkVerificationTokenEnable, setCheckVerificationTokenEnable] =
    useState(false);
  const [verifyUserMailEnable, setVerifyUserMailEnable] = useState(false);
  const [forgotPasswordEnable, setForgotPasswordEnable] = useState(false);

  // const [snakeBarProps, setSnakeBarProps] = useState({});
  const [verifyUserEmailStatus, setCheckVerifyUserEmailStatus] = useState("");
  const [resetPasswordStatus, setResetPasswordStatus] = useState("");
  const [signinStatus, setSigninStatus] = useState("");
  const [signupStatus, setSignupStatus] = useState("");
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("");
  const [resendLinkEnable, setResendLinkEnable] = useState(false)
  const [resendLinkStatus, setResendLinkStatus] = useState('')
  const [checkVerificationStatus, setCheckVerificationStatus] = useState("");
  const [payload, setPayload] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSnackbar] = useSnackMessage();
  const appleSignReducer = useSelector((state) => state.appleSignReducer);
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('request');


  //Function to set message in snackdrawer

  // const setMessage = (data) => {
  //   var messageType = "success";
  //   switch (data?.http_code) {
  //     case 200: messageType = "success";
  //       break;
  //     case 404: ;
  //     case 422: ;
  //     case 401: ;
  //     case 400: ;
  //     default: messageType = "error"
  //   }
  //   setSnakeBarProps({ type: messageType, flag: true, message: data?.message });
  // }

  //POST API Request to register new user
  const { mutate: userSignupMutate, isLoading: userSignupLoading } =
    useMutation(userSignup, {
      onSuccess: (response, variables, context) =>
        userSignupSucessHandler(response, variables, context),
      onError: (error, variables, context) =>
        userSignupErrorHandler(error, variables, context),
    });

  const userSignupSucessHandler = (response, variables, context) => {
    const responseObject = response?.data;
    if (responseObject?.http_code == 200) {
      gaEvents.signupEvent();
      setSignupStatus("success");
    }
  };

  const userSignupErrorHandler = (error, variables, context) => {
    const errorObject = error?.response?.data;
    gaEvents.signupFailed();
    showSnackbar(errorObject);
    // dispatch(setSnackbar({ ...setSnackMessageType(errorObject) }));
    // if (errorObject?.http_code == 422) {
    //   setSnakeBarProps({
    //     type: "error",
    //     flag: true,
    //     message: errorObject?.message,
    //   });
    // }
    setSignupStatus("error");
  };

  //POST API Request to signin verified user
  const { mutate: userSigninMutate, isLoading: userSigninLoading } =
    useMutation(userSignin, {
      onSuccess: (response, variables, context) =>
        userSigninSucessHandler(response, variables, context),
      onError: (error, variables, context) =>
        userSigninErrorHandler(error, variables, context),
    });

  const userSigninSucessHandler = (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {

      gaEvents.loginEvent()


      if (appleSignReducer.is_redirected_from_butterfly && window.localStorage.getItem('logout_all') !== 'true') {
        dispatch(updateAppleSignState({ is_redirected_from_butterfly: false }))
        dispatch(updatePendingCallState({ redirect_backdrop_open: true }))
        // window.location.href = `http://localhost:3001`;
        const encryptedToken = encryptString(responseData?.token);
        const encodedURIString = encodeURIComponent(encryptedToken);
       
        window.location.href = `${process.env.REACT_APP_COPILOT_URL}/redirect/${encodedURIString}`;
        window.localStorage.setItem("token", responseData?.token);
      } else {
        // dispatch(updateState({is_logged:true}))
        // 
        window.localStorage.setItem("token", responseData?.token);
        getPermissions(dispatch)
        getTheme(dispatch)
        setSigninStatus("success");
        window.localStorage.removeItem('logout_all')
        dispatch(updateState({logout_all: false}))
        navigate("/dashboard", { replace: true });
      }

      dispatch(
        updateState({
          // is_logged: true,
          user_name: responseData?.name,
          user_id: responseData?.user_id,
          company_name: responseData?.company_name,
          show_revision_tutorial: responseData?.show_revision_tutorial,
          is_company_name_available: responseData?.company_name ? true : false,
          signin_loading: false,
          approver_allowed:  responseData?.approver_allowed
        })
      );

      dispatch(setAssetState({
        is_brand_name_available: responseData?.company_name ? true : false,
      }))
      dispatch(updatePendingCallState({ redirect_backdrop_open: false }))




      // navigate("/", {replace: true});



    }

  };

  const userSigninErrorHandler = (error, variables, context) => {
    const errorObject = error?.response?.data;

    if (errorObject.data && errorObject.data.email_verified == false) {
      showSnackbar(errorObject)
      setSigninStatus("unverified");
    } else {
      setSigninStatus("error");
      gaEvents.loginFailed()
      showSnackbar(errorObject)
    }

    // dispatch(setSnackbar({ ...setSnackMessageType(errorObject)}));

  };

  //POST API Request to register new user
  const { mutate: resetPasswordMutate, isLoading: resetPasswordLoading } =
    useMutation(resetPassword, {
      onSuccess: (response, variables, context) =>
        resetPasswordSucessHandler(response, variables, context),
      onError: (error, variables, context) =>
        resetPasswordErrorHandler(error, variables, context),
    });

  const resetPasswordSucessHandler = (response, variables, context) => {
    const responseObject = response?.data;
    if (responseObject?.http_code == 200) {
      setResetPasswordStatus("success");
      // showSnackbar(responseObject);
      //dispatch(setSnackbar({ ...setSnackMessageType(responseObject) }));
    }
  };

  const resetPasswordErrorHandler = (error, variables, context) => {
    const errorObject = error?.response?.data;
    showSnackbar(errorObject)
    // dispatch(setSnackbar({ ...setSnackMessageType(errorObject) }));
    setResetPasswordStatus("error");

  };

  // Get Request for Forgot Password

  const {
    data: forgotPasswordData,
    error: forgotPasswordError,
    isLoading: forgotPasswordLoading,
  } = useQuery(
    [`forgotPassword${payload?.email}`, payload?.email],
    () => forgotPassword(payload),
    { enabled: forgotPasswordEnable, retry: false }
  );

  useEffect(() => {
    setForgotPasswordEnable(false);
    if (forgotPasswordData && forgotPasswordData?.data?.http_code == 200) {
      showSnackbar(forgotPasswordData?.data)
      // dispatch(setSnackbar({ ...setSnackMessageType(forgotPasswordData?.data) }));
      setForgotPasswordStatus("success");
    }
    if (forgotPasswordError) {
      setForgotPasswordStatus("error");
      showSnackbar(forgotPasswordError?.response?.data)
      forgotPasswordCallback && forgotPasswordCallback()
    }

    // return ()=>{ setStatus("")}
  }, [forgotPasswordData, forgotPasswordError]);

  const forgotPasswordRequest = (email) => {
    setPayload(email);
    setForgotPasswordEnable(true);
  };

  ///////////////GET request for resend link////////////////////////////////

  const {
    data: resendLinkData,
    error: resendLinkError,
    isLoading: resendLinkLoading,
  } = useQuery(
    [`resendLink${payload?.email}`, payload?.email],
    () => resendLink(payload),
    { enabled: resendLinkEnable, retry: false }
  );

  useEffect(() => {
    setResendLinkEnable(false);
    // console.log('resendLink data',resendLinkData.data)

    if (resendLinkData && resendLinkData?.data?.http_code == 200) {
      showSnackbar(resendLinkData?.data)
      // dispatch(setSnackbar({ ...setSnackMessageType(forgotPasswordData?.data) }));
      setResendLinkStatus("success");
    }

    if (resendLinkError) {
      if (resendLinkError?.response?.data?.data?.email_verified == true) {
        setResendLinkStatus("verified");
      } else {
        setResendLinkStatus("error");
      }
      showSnackbar(resendLinkError?.response?.data)

    }
  }, [resendLinkData, resendLinkError]);

  const resendLinkRequest = (email) => {
    setPayload(email);
    setResendLinkEnable(true);
  };


  // Get Request to check validity of verification Token

  const {
    data: checkVerificationTokenData,
    error: checkVerificationTokenError,
    isLoading: checkVerificationTokenLoading,
  } = useQuery(
    [`checkToken${payload?.token}`, payload?.token],
    () => checkVerificationToken(payload),
    { enabled: checkVerificationTokenEnable, retry: false }
  );

  useEffect(() => {
    setCheckVerificationTokenEnable(false);
    if (checkVerificationTokenData) {
      setCheckVerificationStatus("success");
    }
    if (checkVerificationTokenError) {
      setCheckVerificationStatus("error");
    }

    //  return ()=>{ setStatus("")}
  }, [checkVerificationTokenData, checkVerificationTokenError]);

  const checkVerifyToken = (token) => {
    setPayload(token);

    setCheckVerificationTokenEnable(true);
  };

  // Get Request to verify User's Emal address

  const {
    data: verifyUserMailData,
    error: verifyUserMailError,
    isLoading: verifyUserMailLoading,
  } = useQuery(
    [`verifyMail${payload?.token}`, payload?.token],
    () => verifyUserMail(payload),
    { enabled: verifyUserMailEnable, retry: false }
  );

  useEffect(() => {
    setVerifyUserMailEnable(false);
    if (verifyUserMailData?.data?.http_code == 200) {
      if (verifyUserMailData?.data?.data?.already_verified == true) {
        setCheckVerifyUserEmailStatus("already_verified");
      } else {
        setCheckVerifyUserEmailStatus("success");
      }
    }
    if (verifyUserMailError) {
      setCheckVerifyUserEmailStatus("error");
    }

    //  return ()=>{ setStatus("")}
  }, [verifyUserMailData, verifyUserMailError]);

  const verifyEmail = (token) => {
    setPayload(token);
    setVerifyUserMailEnable(true);
  };

  useEffect(() => {
    if (userSignupLoading) {
      setSignupStatus("loading");
    }
    if (userSigninLoading) {
      setSigninStatus("loading");
    }
    if (resetPasswordLoading) {
      setResetPasswordStatus("loading");
    }
    if (forgotPasswordLoading) {
      setForgotPasswordStatus("loading");
    }
    if (resendLinkLoading) {
      setResendLinkStatus("loading");
    }
    if (verifyUserMailLoading) {
      setCheckVerifyUserEmailStatus("loading");
    }

    // return ()=>{ setStatus("")}
  }, [
    userSigninLoading,
    userSignupLoading,
    resetPasswordLoading,
    forgotPasswordLoading,
    verifyUserMailLoading,
    resendLinkLoading
  ]);

  //Functions to request API as per incoming mutation type
  const authRequest = (data) => {
    switch (requestType) {
      case "signin":
        return userSigninMutate(data);
      case "signup":
        return userSignupMutate(data);
      case "forgot-password":
        return forgotPasswordRequest(data);
      case "verify-mail":
        return verifyEmail(data);
      case "verify-token":
        return checkVerifyToken(data);
      case "reset-password":
        return resetPasswordMutate(data);
      case "resend-link":
        return resendLinkRequest(data)
      default:
        return () =>
          console.log(
            "requested operation is not available. Available options: signin | signup | forgot-password | reset-password"
          );
    }
  };

  return [
    authRequest,
    requestType == "reset-password"
      ? resetPasswordStatus
      : requestType == "signin"
        ? signinStatus
        : requestType == "signup"
          ? signupStatus
          : requestType == "forgot-password"
            ? forgotPasswordStatus
            : requestType == "verify-token"
              ? checkVerificationStatus
              : requestType == "verify-mail"
                ? verifyUserEmailStatus
                : requestType == "resend-link"
                  ? resendLinkStatus : "",
  ];
};
