import { Box, Button, Typography } from "@mui/material"
import CustomButton from "../../../components/CustomButton"
// import { customTheme as theme } from "../../../theme/customTheme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import CustomCard from '../../events/components/CustomCard'
import { updateReviewState } from "../../../redux/reviewSlice"
import AcceptVideoPopup from "./AcceptVideoPopup"
import { openInNewTab } from "../../../utils/commonServices"
import useAcceptFinalVideo from "../hooks/useAcceptFinalVideo"
import useRevisionRequests from "../hooks/useRevisionRequests"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import editImage from "../../../assets/edit.svg"
import usePermissions from "../../../hooks/usePermissions"
import { PERMISSION } from "../../../constants/constants"
import { createNewRevision, disableRevisionTutorialRequest } from "../actions"
import { getEventDetails } from "../../events/actions"
import useCustomTheme from "../../../theme/useCustomTheme"
import useRevisionActions from "../useRevisionActions"

const VideoEditedCompletedCard = (props) => {

    const {message} = props
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [acceptFinalVideoMutate , acceptFinalVideoStatus] = useAcceptFinalVideo()
   // const [createRevision, createRevisionStatus] = useRevisionRequests('create-revision')
   // const [disableRevisionTutorial, disableRevisionTutorialStatus] = useRevisionRequests('disable-revision-tutorial')
    const reviewEventReducer = useSelector((state)=> state.reviewEventReducer)
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()
   // const location = useLocation()
    const navigate = useNavigate()
    const [hasPermission] = usePermissions()
  
    const {sendVideoForApproval} = useRevisionActions()
    const videoAcceptHandler =  ()=>{
        //console.log('Video accepted')// send api req to accept video
        dispatch(updateReviewState({open_video_accept_popup :true}))
        // openInNewTab()
    }

    const sendForApprovalHandler =  ()=>{
      //console.log('Video accepted')// send api req to accept video
      // dispatch(updateReviewState({open_video_accept_popup :true}))
      const payload = {
        final_video_id: commonReducer?.event_details?.final_video_id
      }
      sendVideoForApproval(payload)
      // openInNewTab()
  }

    const requestChangesHandler = ()=>{
        // if(location.pathname != '/completedevent') {
        //   navigate('/completedevent')
        // }
       if(commonReducer.event_details.revision.id == null || commonReducer.event_details.revision.submitted == true) {
        if(commonReducer.show_revision_tutorial) {
          dispatch(updateReviewState({open_change_request_tutorial_popup : true}))
           disableRevisionTutorialRequest(dispatch, navigate, {commonReducer})
        }
         createNewRevision(dispatch, navigate, {commonReducer}, {event_id: commonReducer.production_event_id, final_video_id: commonReducer.event_details.final_video_id})
        } else {
          dispatch(updateReviewState({request_changes: true}))
    }
        }
       // 

       useEffect(()=> {
        if(acceptFinalVideoStatus == "success") {
         // refetchDetails()
         getEventDetails({id: commonReducer.production_event_id}, dispatch)
        }
       }, [acceptFinalVideoStatus])
   
  


  return (
    <CustomCard  style={{height:'100%', }} >
    <Box sx={{display:'flex', height:'100%', width:'100%'}}>

        <Box  gap={{xs:0,md:1}} sx={{display:'flex', flexDirection:'column', paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' , justifyContent:'center', alignItems:'start', width:'60%'}}>
         
                  <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600, letterSpacing:0, fontFamily:'Rubik'}}>{message}</Typography>
                  <Typography sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px'}}>How would you like to proceed?</Typography>  
              
        </Box>

        <Box  sx={{ display:'flex', alignItems:'flex-end', justifyContent:'center', height:'100%' ,width:'40%',  flexDirection:'column', mr: "5%"}}>    
        {
         //hasPermission(PERMISSION.PROJECT.REVISION.ACCEPT_VIDEO) &&
         commonReducer?.event_details?.can_approve ? 
         <CustomButton 
              btnText='Accept Video'
              disabled={!hasPermission(PERMISSION.PROJECT.REVISION.ACCEPT_VIDEO)}
              onClick={videoAcceptHandler}    
              type='button'
              color='secondary'
              variant='contained'
              style={{
              borderRadius: '5px',
              border: "none",          
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
            /> :       <CustomButton 
              btnText='Send for Approval'
              disabled={commonReducer?.event_details?.sent_for_approval}
              onClick={sendForApprovalHandler}    
              type='button'
              color='secondary'
              variant='contained'
              style={{
              borderRadius: '5px',
              border: "none",          
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
            /> }
            {
              commonReducer.event_details.revision_left > 0 || commonReducer.event_details?.unlimited_revision ?
               //hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.CREATE) && 
               <Button 
                variant="text"
                disabled = {commonReducer.under_revision && !hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.CREATE) || !commonReducer?.event_details?.enable_revision}
                onClick={requestChangesHandler}
                sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                    or Request Revision
                </Button> : 
                  <Typography sx={{textAlign: 'right', fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px'}}> Contact Lucihub sales for more revisions</Typography>  

            }
            
                  </Box>
        </Box>
        { reviewEventReducer.open_video_accept_popup && <AcceptVideoPopup open={reviewEventReducer.open_video_accept_popup} acceptRequest = {acceptFinalVideoMutate} status = {acceptFinalVideoStatus == "loading"} />}
    </CustomCard>
  )
}

export default VideoEditedCompletedCard