import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showSnackBar } from "../../utils/commonServices";
import { createGuestLogApi, deleteRevisionLogApi, getGuestEventDetailsApi, registerGuestApi, sendForApprovalApi, updateRevisionLogsApi } from "../../services/api";
import { setGuestReviewState } from "../../redux/guestSlice";
import { setTheme } from "../../redux/customThemeSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { getRevisionLogs } from "./actions";
import { updateReviewState } from "../../redux/reviewSlice";
import { getEventDetails } from "../events/actions";



export default ()=> {
 
  const dispatch = useDispatch();
  const commonReducer = useSelector(state=> state.root.commonReducer)
  const navigate = useNavigate()

   const deleteLog = (values ,callback) => {
    const payload = values
    dispatch(updateReviewState({is_loading: true}))
    deleteRevisionLogApi(payload) 
    .then((response) => {
      dispatch(updateReviewState({is_loading: false}))
      if(response?.data?.http_code === 200){
          dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
          getRevisionLogs(dispatch, navigate, {commonReducer})
          callback && callback()
      }else {
          dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      }
    })
    .catch((error) => {
      dispatch(updateReviewState({is_loading: false}))
      if(error) {
      const errorObject = error?.response?.data;
      showSnackBar(dispatch, errorObject);
      }
    });
    }

    const updateLog = (values ,callback) => {
        const payload = values
        updateRevisionLogsApi(payload) 
        .then((response) => {
          if(response?.data?.http_code === 200){
              dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
              dispatch(updateReviewState({edit_flag: false, log_id: ''}))
              getRevisionLogs(dispatch, navigate, {commonReducer})
              callback && callback()
          }else {
              dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
          }
        })
        .catch((error) => {
          if(error) {
          const errorObject = error?.response?.data;
          showSnackBar(dispatch, errorObject);
          }
        });
        }

        const sendVideoForApproval = (payload ,callback) => {
          sendForApprovalApi(payload) 
          .then((response) => {
            if(response?.data?.http_code === 200){
                dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
                // dispatch(updateReviewState({edit_flag: false, log_id: ''}))
                // getRevisionLogs(dispatch, navigate, {commonReducer})
                 getEventDetails({id: commonReducer.production_event_id}, dispatch);
                callback && callback()
            }else {
                dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            }
          })
          .catch((error) => {
            if(error) {
            const errorObject = error?.response?.data;
            showSnackBar(dispatch, errorObject);
            }
          });
          }
    

  return { deleteLog, updateLog, sendVideoForApproval};
}