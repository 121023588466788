import { getRoleNamesFromIds, getUserRoles } from "../../commonactions/actions";
import { updateState } from "../../redux/commonSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { updateTeamState } from "../../redux/teamSlice";
import { addOrganisationMemberApi, cancelEvent, changeOrganisationAdminApi, checkSessionApi, deleteUser, eventsList, getAllTeamsApi, getPermissionsApi, removeOrganisationAdminApi, removeOrganisationMemberApi, updateApproverRoleApi, updateButterflyAccessApi, updateOrganisationMemberRoleApi, viewOrganisationMembersApi } from "../../services/api"
import { logout, showSnackBar } from "../../utils/commonServices";


//////////////////////// GET ORGANISATION MEMBERS API //////////////////////////////////

export const viewOrganisationMember = (dispatch, navigate,commonReducer ) => {

viewOrganisationMembersApi() 
.then((response) => {
 
  if(response?.data?.http_code === 200){
     dispatch(updateTeamState({organization_members: response?.data?.data, organisation_members: response?.data?.data}))
     dispatch(updateState({copilot_access:response?.data?.data?.find(mem => mem.id === commonReducer.user_id)?.role?.copilot_access }))
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    // console.log('view org mem', error)
 // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  }
});
}


///////////////////////// ADD MEMBERS TO THE ORGANISATION API /////////////////////////////

export const addOrganisationMember = (values ,dispatch) => {
const payload = values
addOrganisationMemberApi(payload) 
.then((response) => {

  if(response?.data?.http_code === 200){
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
 // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  }
});
}


///////////////////////// CHANGE ORGANISATION MEMBER ROLE API ////////////////////////////

export const updateOrganisationMemberRole = (values ,dispatch ,setSelectedRole, role,  navigate, state) => {
  
  const payload = values
  dispatch(updateTeamState({is_loading: true}))
updateOrganisationMemberRoleApi(payload) 
.then((response) => {
  if(response?.data?.http_code === 200){
    viewOrganisationMember(dispatch,navigate, state.commonReducer )
    getUserRoles(dispatch, navigate, state.permissionsReducer)
    dispatch(updateTeamState({is_loading: false}))
    setSelectedRole(role?.name)
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
    dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    dispatch(updateTeamState({is_loading: false}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
 // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  }
  dispatch(updateTeamState({is_loading: false}))
});
}


//////////////////////////// CHANGE ORGANISATION ADMIN API ////////////////////////////

export const changeOrganisationAdmin = (values ,dispatch, navigate, state ) => {
  const payload = values
  dispatch(updateTeamState({loading_state: 'loading'}))
changeOrganisationAdminApi(payload) 
.then((response) => {
  // console.log({response})
 
  if(response?.data?.http_code === 200){
      viewOrganisationMember(dispatch, navigate, state.commonReducer)
      getUserRoles(dispatch, navigate, state.permissionsReducer)
      dispatch(updateTeamState({loading_state: 'success'}))
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      dispatch(updateTeamState({loading_state: 'error'}))
  }
})
.catch((error) => {
  console.error('error:', error);
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
  //dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  dispatch(updateTeamState({loading_state: 'error'}))
  }
});
}


///////////////////////////// REMOVE ORGANISATION ADMIN API ////////////////////////////////

export const removeOrganisationAdmin = (values ,dispatch, navigate, state ) => {
  const payload = values
  dispatch(updateTeamState({loading_state: 'loading'}))
removeOrganisationAdminApi(payload) 
.then((response) => {
  // console.log({response})
 
  if(response?.data?.http_code === 200){
      viewOrganisationMember(dispatch, navigate, state.commonReducer)
      dispatch(updateTeamState({loading_state: 'success'}))
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      dispatch(updateTeamState({loading_state: 'error'}))
  }
})
.catch((error) => {
  if(error) {
  // console.error('error:', error);
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
  //dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  dispatch(updateTeamState({loading_state: 'error'}))
  }
});
}


////////////////////////////// REMOVE ORGANISATION MEMBER API /////////////////////////////////

export const removeOrganisationMember = (values ,dispatch, navigate , state) => {
  const payload = values
  dispatch(updateTeamState({loading_state: 'loading'}))
removeOrganisationMemberApi(payload) 
.then((response) => {

  if(response?.data?.http_code === 200){
      viewOrganisationMember(dispatch, navigate, state.commonReducer)
      dispatch(updateTeamState({loading_state: 'success'}))
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message }))
  }else {
      dispatch(updateTeamState({loading_state: 'error'}))
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  // console.error('error:', error);
  if(error) {
  dispatch(updateTeamState({loading_state: 'error'}))
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
  //dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  }
});
}


///////////////////////////////// GET ALL TEAMS ///////////////////////////////////////////

export const getAllTeams = (dispatch, navigate ) => {

  getAllTeamsApi() 
  .then((response) => {
 
    if(response?.data?.http_code === 200){
       dispatch(updateTeamState({all_teams: response?.data?.data }))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    // console.error('error:', error);
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    //dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    }
  });
  }

  ///////////////////////////////// GET ALL EVENTS ///////////////////////////////////////////

  export const getAllEvents = (dispatch, navigate, state ) => {
   // const payload = values
 eventsList()
  .then((response) => {
    if(response?.data?.http_code === 200){
      dispatch(
        updateState({
         events: response?.data?.data?.event_details,
         status_filtered_events: response?.data?.data?.event_details,
         type_filtered_events: response?.data?.data?.event_details,
         combined_filter: response?.data?.data?.event_details,
        })
      );
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    // console.error('error:', error);
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    }
  });
  }


  /////////////////////////////////// DELETE USER ///////////////////////////////////////////
export const deleteUserApi = (dispatch ) => {
  deleteUser() 
  .then((response) => {
   
    if(response?.data?.http_code === 200){
      logout(dispatch)
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
    }
  });
  }
/////////////////////////////DELETE PROJECT /////////////////////////////////////////////
export const deleteProject = (dispatch ) => {
  cancelEvent() 
  .then((response) => {
   
    if(response?.data?.http_code === 200){
      logout(dispatch)
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
    }
  });
  }


  ///////////////////////// CHANGE BUTTERFLY ACCESS API ////////////////////////////

export const updateButterflyAccess = (values ,dispatch , navigate, state) => {
  
  const payload = values
  dispatch(updateTeamState({is_loading: true}))
updateButterflyAccessApi(payload) 
.then((response) => {
  if(response?.data?.http_code === 200){
    viewOrganisationMember(dispatch, navigate, state?.commonReducer)
    getUserRoles(dispatch, navigate, state?.permissionsReducer)
    dispatch(updateTeamState({is_loading: false}))
    // setSelectedRole(role?.name)
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
    dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    dispatch(updateTeamState({is_loading: false}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    // console.log('error object', error)
 // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  }
  dispatch(updateTeamState({is_loading: false}))
});
}


///////////////////////////////// update approver role  ///////////////////////////////////////////////////////


export const updateApproverRole = (values ,dispatch , navigate, state) => {
  
  const payload = values
  dispatch(updateTeamState({is_loading: true}))
updateApproverRoleApi(payload) 
.then((response) => {
  if(response?.data?.http_code === 200){
    viewOrganisationMember(dispatch, navigate, state?.commonReducer)
    getUserRoles(dispatch, navigate, state?.permissionsReducer)
    dispatch(updateTeamState({is_loading: false}))
    // setSelectedRole(role?.name)
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
    dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    dispatch(updateTeamState({is_loading: false}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
  }
  dispatch(updateTeamState({is_loading: false}))
});
}


export const checkTokenSession = (dispatch , callback) => {
  checkSessionApi() 
  .then((response) => {
    if(response?.data?.http_code === 200){
      callback && callback()
    }
  })
  .catch((error) => {
      const errorObject = error?.response?.data;
      // showSnackBar(errorObject)
      dispatch(setSnackbar({type:'error', flag:true, message: 'Your session has expired. Please login again.'}))
      setTimeout(() => {
        logout(dispatch)
      }, 3000);
  });
}