//import * as AWS from "aws-sdk";
import { S3, S3Client } from "@aws-sdk/client-s3";
import { useDispatch } from "react-redux";
import moment from 'moment-timezone';
import { useMonthPickerDefaultizedProps } from "@mui/x-date-pickers/MonthPicker/MonthPicker";
import { resetAppleSignState } from "../redux/appleSignSlice";
import { resetState, updateState } from "../redux/commonSlice";
import { emptyDropdownArray } from "../redux/dropdownSlice";
import { resetCreationState } from "../redux/eventCreationSlice";
import { resetAllMediaFilesArray } from "../redux/mediaFilesSlice";
import { resetSnackbar, setSnackbar } from "../redux/messageSlice";
import { resetPermissionState } from "../redux/permissionSlice";
import { resetProjectState } from "../redux/projectSlice";
import { resetReviewState } from "../redux/reviewSlice";
import { resetTeamPageData, resetTeamState } from "../redux/teamSlice";
import { STATUS_TYPE } from "../constants/constants";
import { resetAssetState } from "../redux/assetSlice";
import { resetTheme } from "../redux/customThemeSlice";
import { encryptString } from "./encryptionServices";


export const setSnackMessageType = (dataObject) => {
  const tempSnackObject = {flag: true, message: dataObject?.message}
  switch (dataObject?.http_code) {
    case 200: return {...tempSnackObject, type : "success"};
    case 404: ;
    case 422: ;
    case 401: ;
    case 400: ;
    default: return {...tempSnackObject, type : "error"};
  }
}

export const setInput = (value, type, pageData, setPageData) => {
  setPageData({ ...pageData, [type]: value });
};

export const getInitials = (str) => {
  if(str === null || str === undefined){
    return 'NA'
  }
    var names = str?.split(' '),
        initials = names[0]?.substring(0, 1)?.toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
    }
    return initials;

}


export const afterValidate = (callBack) => {
  // console.log('in after validate')
  var errorMszDom = [];
  setTimeout(() => {
    errorMszDom = document.getElementsByClassName("errorDom");
    if (errorMszDom.length == 0) {
      // console.log("error not found")
      callBack();
    } else {
      // console.log('error found')
      // console.log({errorMszDom})
      // setSnakeBarProps({ snackbarFlag: true, msz: "Please fill all the required field", type: "error" })
    }
  });
};

export const timeStampToString = (UNIX_timestamp) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  // var date = "";
  // if(a.getDate()%10 == 1 && a.getDate() != 11) {
  //     date=`${a.getDate()}st`
  // } else if(a.getDate()%10 == 2 && a.getDate() != 12 ) {
  //     date=`${a.getDate()}nd`
  // } else if(a.getDate()%10 == 3 && a.getDate() != 13 ) {
  //     date=`${a.getDate()}rd`
  // } else {
  //     date=`${a.getDate()}th`
  // }
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var dateString = date + ' ' + month + ' ' + year ;
  var dateString = month + " " + date + ", " + year;
  return dateString;
};

export const timeStampToDayString = (UNIX_timestamp, format) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var day = days[a.getDay()];
  // var date = "";
  // if(a.getDate()%10 == 1 && a.getDate() != 11) {
  //     date=`${a.getDate()}st`
  // } else if(a.getDate()%10 == 2 && a.getDate() != 12 ) {
  //     date=`${a.getDate()}nd`
  // } else if(a.getDate()%10 == 3 && a.getDate() != 13 ) {
  //     date=`${a.getDate()}rd`
  // } else {
  //     date=`${a.getDate()}th`
  // }
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var dateString = date + ' ' + month + ' ' + year ;

  if(format == "phone") {
    var dateString = day?.slice(0, 3) + ", " + month + " " + date + ", " + year;
  } else {
    var dateString = day?.slice(0, 3) + ", " + (a.getMonth()+1) + "/" + date + "/" + year;
  }
  
  return dateString;
};


export const timeStampToDateString = (UNIX_timestamp, format) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var day = days[a.getDay()];


  if(format == "phone") {
    var dateString = day + ", " + month + " " + date + ", " + year;
  } else {
    var dateString =   (a.getMonth()+1) + "/" + date + "/" + year;
  }
  
  return dateString;
};



export const isEventDate = (startTimeStamp, endTimeStamp) => {
 // const endTime = parseInt(endTimeStamp);
  const a = new Date(parseInt(startTimeStamp) * 1000);
  const b = new Date(parseInt(endTimeStamp) * 1000);
  const c = new Date();
  const startDate = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  const endDate = new Date(b.getFullYear(), b.getMonth(), (b.getDate()+1));
  const currenDate = new Date(c.getFullYear(), c.getMonth(), c.getDate())

  if( currenDate >= startDate && currenDate <= endDate) {
      return true;
  } else {
      return false
  }
  // const currenDate = Math.floor(Date.now() / 1000);
  // if (currenDate <= (endTime + (24 * 3600))) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const isEventPending = (startTimeStamp)=> {
  const a = new Date(parseInt(startTimeStamp) * 1000);
  const b = new Date();
  const startDate = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  const currenDate = new Date(b.getFullYear(), b.getMonth(), b.getDate());

  if( currenDate < startDate) {
      return true;
  } else {
      return false
  }
}

export const isEventEnd = (endTimeStamp)=> {
  const a = new Date(parseInt(endTimeStamp) * 1000);
  const b = new Date();
  const endDate = new Date(a.getFullYear(), a.getMonth(), (a.getDate()+1));
  const currenDate = new Date(b.getFullYear(), b.getMonth(), b.getDate());

  if( currenDate > endDate) {
      return true;
  } else {
      return false
  }
}

export const timeStampToNumberedDateString = (UNIX_timestamp) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var dateString =
    (month < 10 ? "0" + month : month) +
    "-" +
    (date < 10 ? "0" + date : date) +
    "-" +
    year;
  return dateString;
};
export const isEditStarted = (editStartTimestamp) => {
  const current = Date.now()/1000;
  if(current < parseInt(editStartTimestamp)) {
    return false
  } else {
    return true
  }
}

export const timeStampToDateTime = (UNIX_timestamp) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var dateString =
    (month < 10 ? "0" + month : month) +
    "-" +
    (date < 10 ? "0" + date : date) +
    "-" +
    year;
  // return a.toLocaleString() ;
  const dateTimeString = a.toLocaleString();

  const stringWithoutSeconds = dateTimeString.substring(
    0,
    dateTimeString.lastIndexOf(":")
  );
  return stringWithoutSeconds;
};


//  export const timeStampToFormatTimeZone = (UNIX_timestamp) => {
//   const date = new Date(parseInt(UNIX_timestamp) * 1000);
//   const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
//   const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

//   const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   console.log({timeZoneId})
//   const timeZoneOffset = date.getTimezoneOffset();
//   console.log({timeZoneOffset})
//   // const timeZone = moment.tz.zone(timeZoneId).abbr(timeZoneOffset)
//   const timeZone = moment.tz.zone(timeZoneId).abbr(timeZoneOffset)
//   console.log({timeZone})
  
//   return `${formattedTime} (${timeZone})`;
// };


export const timeStampToFormatTimeZone = (UNIX_timestamp) => {
  const date = new Date(parseInt(UNIX_timestamp) * 1000);
  const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);


  const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
 // console.log([date.getFullYear(), date.getMonth(), date.getDate()], moment([date.getFullYear(), date.getMonth(), date.getDate()]).isDST())
  //console.log({timeZoneId})
  const timeZoneOffset = date.getTimezoneOffset();
  //console.log({timeZoneOffset})
  const timeZone = moment([date.getFullYear(), date.getMonth(), date.getDate()]).isDST() ? 
                        moment.tz(timeZoneId).format('z') :
                          moment.tz.zone(timeZoneId).abbr(timeZoneOffset)
  //const timeZone = moment.tz(timeZoneId).format('z')
  //console.log(moment.tz.names())
  
  return `${formattedTime} (${timeZone})`;
};

//   // Check if DST is active for the given timestamp and time zone
//   const isDST = moment.tz(date, timeZoneId).isDST();

//   // Get the abbreviation for the current time zone offset (standard or daylight saving time)
//   const timeZoneAbbreviation = moment.tz(date, timeZoneId).format('z');

//   // return `${formattedTime} (${timeZoneAbbreviation}${isDST ? ' DST' : ''})`;
//   return `${formattedTime} (${timeZoneAbbreviation})`;
// };


export const base64ToBlob = async (base, name) => {
  const imageName = name.substring(0, name.lastIndexOf(".")) + ".png";

  const base64Response = await fetch(base);
  // const blob = URL.createObjectURL(base64Response)
  const blob = await base64Response.blob();
  const myFile = new File([blob], imageName, {
    type: blob.type,
  });
  return myFile;

  //   if(!file) {
  //     setBlob('');
  //     return;
  //   }
  // just use the URL.createObjectURL to convert to blob
  //   setBlob(URL.createObjectURL(file))
  //}

  //     var url = base

  //    return fetch(url)
  //     .then(res => res.blob())
};

export const removeDuplicateArrayItem = (arr) => {
  const uniqueArray = arr.filter(
    (object, index) =>
      index == arr.findIndex((obj) => obj.id == object.id)
  );
  return uniqueArray;
};

export const isAcceptedFormat = (fileName) => {
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  if (
    extension.toUpperCase() == "MP4" ||
    extension.toUpperCase() == "MOV" ||
    extension.toUpperCase() == "WEBM" ||
    extension.toUpperCase() == "OGG"
  ) {
    return true;
  } else {
    return false;
  }
};

export const fileFormat = (fileName) => {
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return extension
};
export const removeExtension = (fileName) => {
const nameWithoutExtension = fileName?.substring(0, fileName?.lastIndexOf('.')) || fileName
return nameWithoutExtension; // Output: "example"
};

export const sizeConverter = (bytes) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

//functions for ios detection

export const  isIos = ()=> {
  var iOS = 
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !window.MSStream;
  return iOS;
}

export const redirectToApp = (appId, queryString) => {
  if (isIos()){
    window.location.href = (`https://apps.apple.com/app/${appId}?${queryString}`)
   } else {
    window.location.href = "/";
   }
}

// Function for platform detection

export const getPlatform = ()=> {
  if (navigator.userAgentData?.platform) {
      return navigator.userAgentData.platform;  // Works in Chromium browsers
  }

  // Fallback for Safari and other browsers
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("mac")) return "macOS";
  if (userAgent.includes("win")) return "Windows";
  if (userAgent.includes("linux")) return "Linux";
  if (/iphone|ipad|ipod/.test(userAgent)) return "iOS";
  if (userAgent.includes("android")) return "Android";

  return "Unknown";
}

export const openInNewTab = (url) => {
  // const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  const pdfWindow = window.open();
  pdfWindow.location.href = url; //'https://s3.us-central-1.wasabisys.com/files-storage-staging/final_video/2/wasabi_sample.mp4'
}

export const getFileNameFromUrl= (url)=> {
  const regex = /\/([^/]+\.\w{3,4})(\?.*)?$/;
  const match = url?.match(regex);
  return match ? match[1] : null;
}


export const downloadS3File = url => {
  //const s3Url = 'https://your-s3-bucket.s3.amazonaws.com/your-video.mp4';
  
  const fileName = getFileNameFromUrl(url);

 
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
 
  // fetch(s3Url)
  // .then(response => response.blob())
  // .then(blob => {
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', videoName);
  //   document.body.appendChild(link);
  //   link.click();
  // });
  
  
  
}

export const getFileType = (fileName) => {

  const fileExtension = fileFormat(fileName).toLowerCase();

  switch (fileExtension) {
    case 'mp4':
    case 'mov':
    case 'ogg':
      return 'video';
    case 'mp3':
    case 'wav':
      return 'audio';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
      return 'image';
    case 'doc':
    case 'docx':
    case 'pdf':
    case 'rtf':
    //case 'txt':
      return 'application';
    default:
      return 'unknown';
  }
};

export const dateCountdown = (UNIX_timestamp) => {
 
    const now = new Date().getTime();
    const futureDate = new Date(parseInt(UNIX_timestamp) * 1000).getTime();
    // console.log('now ', now)
    // console.log('futureDate ', futureDate)
    const daysRemaining = futureDate - now
    // console.log(daysRemaining)
    const days = Math.floor(daysRemaining / (1000 * 60 * 60 * 24));
    if (days < 1) {
      // setEditingStartsIn(0)
      return 0
    }
 
    return days

};

export const groupArrayByValue = (arr, value)=> {
  const groupedArrays = {};
  
  arr.forEach(item => {
    const key = item[value];  // Assuming each item is an object and you want to group by a specific key
    if (!groupedArrays[key]) {
      groupedArrays[key] = [];
    }
    groupedArrays[key].push(item);
  });
  
  return Object.values(groupedArrays);
}


export const functionFormatTime = (seconds)=>{
  const date = new Date(seconds * 1000);
 const hh = date.getUTCHours().toString().padStart(2, '0');
 const mm = date.getUTCMinutes().toString().padStart(2, '0');
 const ss = date.getUTCSeconds().toString().padStart(2, '0');

 if(hh == 0){
   return `${mm}:${ss}`
 }else{
   return `${hh}:${mm}:${ss}`;
 }
 }


////////////////////////////////RESET REDUX AND LOGOUT LOGIC /////////////////////////////////////

export const resetAllState = (dispatch)=> {
  dispatch(resetState());
  dispatch(resetAllMediaFilesArray());
  dispatch(resetAppleSignState());
  dispatch(resetCreationState());
  dispatch(resetPermissionState());
  dispatch(resetProjectState());
  dispatch(resetReviewState());
  dispatch(resetSnackbar());
  dispatch(resetTeamState());
  dispatch(emptyDropdownArray());
  dispatch(resetAssetState());
  dispatch(resetTheme())
};

export const logout = (dispatch)=> {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("primary");
  window.localStorage.removeItem("secondary");
  window.localStorage.removeItem("brand_logo_url");
  resetAllState(dispatch);
}



//*********************************ARRAY FUNCTIONS FOR PERMISSIONS***************** */

export const findKeyAnywhereInJson = (obj, keyToFind)=> {
  const idValues = [];

  function traverse(value) {
      if (typeof value === 'object' && value !== null) {
          if (Array.isArray(value)) {
              for (const item of value) {
                  traverse(item);
              }
          } else {
              for (const key in value) {
                  if (key === keyToFind) {
                      idValues.push(value[key]);
                  } else {
                      traverse(value[key]);
                  }
              }
          }
      }
  }

  traverse(obj);
  return idValues;
}

export const bytesToMegabytes = (bytes) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes;
};


//*********************************SNACKBAR************************************** */

export const showSnackBar = (dispatch, dataObject, callback)=> {
      const tempSnackObject = {flag: true, message: dataObject?.message}
      switch (dataObject?.http_code) {
        case 200:  dispatch(setSnackbar({...tempSnackObject, type : "success"}));
                   break;
        case 401:  logout(dispatch);
                  // dispatch(updateState({logout_all: true}))
                  window.localStorage.setItem('logout_all', "true")
                   window.location.href=`${process.env.REACT_APP_COPILOT_URL}/redirect?request=logout`;
                   dispatch(setSnackbar({...tempSnackObject, type : "error"}));
                   break;
        case 403: ;
        case 404: ;
        case 422: ;
        case 400: ;
        default: dispatch(setSnackbar({...tempSnackObject, type : "error"}));
      }
    }


 export  function generateDarkColor(obj, key) {
      // Get the value associated with the given key
      const value = obj[key];
  
      // Check if the value is undefined
      if (value === undefined) {
          throw new Error(`The key '${key}' does not exist in the object.`);
      }
  
      // Convert the value to a string
      const valueStr = String(value);
  
      // Use a hash function to generate a unique dark color based on the value
      const hash = valueStr.split('').reduce((acc, char) => {
          const charCode = char.charCodeAt(0);
          return ((acc << 5) - acc) + charCode;
      }, 0);
  
      // Adjust the hash to ensure it falls within a dark color range
      const darkHash = (hash % 0x333333) + 0x000000;
  
      // Convert the dark hash to a hexadecimal string
      const darkColor = "#" + ((darkHash >>> 0) & 0xFFFFFF).toString(16).padStart(6, '0');
  
      return darkColor;
  }


  export const getStatusNameFromStatusTypeOrder =(id)=>{
    return STATUS_TYPE?.find(item => item.id === id)?.status;
  }


  export const timeStampToDayInWordsString = (UNIX_timestamp) => {
    var a = new Date(parseInt(UNIX_timestamp) * 1000);
    var monthsShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = a.getDate();
    var monthShort = monthsShort[a.getMonth()];
    var year = a.getFullYear();
  
    var dateString = `${date} ${monthShort} ${year}`;
  
    return dateString;
  };


 export const generateLighterContrastColor = (baseColor) => {
  const hexToRgb = (hex) => {
    // Convert hex to RGB
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  const rgbToHex = (rgb) => {
    // Convert RGB to hex
    return '#' + rgb.map((value) => value.toString(16).padStart(2, '0')).join('');
  };

  const getContrastRatio = (color1, color2) => {
    const luminance = (color) => {
      const sRGB = color.map((value) => {
        value /= 255;
        return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
      });
      return sRGB[0] * 0.2126 + sRGB[1] * 0.7152 + sRGB[2] * 0.0722;
    };

    const luminance1 = luminance(color1);
    const luminance2 = luminance(color2);

    const contrast = luminance1 > luminance2 ? (luminance1 + 0.05) / (luminance2 + 0.05) : (luminance2 + 0.05) / (luminance1 + 0.05);

    return contrast;
  };

  const generateLighterColor = (color, factor) => {
    // Generate a lighter color with the specified factor
    const rgbColor = hexToRgb(color);
    const lighterShade = rgbColor.map((value) => Math.round(value + (255 - value) * factor));

    return rgbToHex(lighterShade);
  };

  // Generate a range of lighter colors with different factors
  for (let i = 1; i <= 5; i++) {
    const lighterColor = generateLighterColor(baseColor, 0.1 * i);
    const contrastRatio = getContrastRatio(hexToRgb(lighterColor), [0, 0, 0]); // Contrast with black

    // Adjust the contrast ratio threshold as needed
    if (contrastRatio >= 6) {
      return lighterColor; // Return the first color that meets the requirement
    }
  }

  // If no color meets the requirement, return the original color
  return baseColor;
};

export const cleanFileName = (str)=> {
   // Remove special characters
   if(!!str) {
    let cleanedString = str?.replace(/[^\w\s]/g, '');
    // Replace white spaces with underscores
    cleanedString = cleanedString.replace(/\s+/g, '_');
    return cleanedString;
   }
  
}



export function convertSecondsToMinutes(seconds) {
  if (seconds <= 90) {
      return `${seconds} seconds`;
  } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (remainingSeconds === 0) {
          return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      } else {
          return `${minutes} minute${minutes > 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
      }
  }
}

// export const generateLighterContrastColor = (baseColor) => {
//   const hexToRgb = (hex) => {
//     // Convert hex to RGB
//     const bigint = parseInt(hex.slice(1), 16);
//     return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
//   };

//   const rgbToHex = (rgb) => {
//     // Convert RGB to hex
//     return '#' + rgb.map((value) => value.toString(16).padStart(2, '0')).join('');
//   };

//   const getContrastRatio = (color1, color2) => {
//     const luminance = (color) => {
//       const sRGB = color.map((value) => {
//         value /= 255;
//         return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
//       });
//       return sRGB[0] * 0.2126 + sRGB[1] * 0.7152 + sRGB[2] * 0.0722;
//     };

//     const luminance1 = luminance(color1);
//     const luminance2 = luminance(color2);

//     const contrast = luminance1 > luminance2 ? (luminance1 + 0.05) / (luminance2 + 0.05) : (luminance2 + 0.05) / (luminance1 + 0.05);

//     return contrast;
//   };

//   const generateLighterColor = (color, factor) => {
//     // Generate a lighter color with the specified factor
//     const rgbColor = hexToRgb(color);
//     const lighterShade = rgbColor.map((value) => Math.round(value + (255 - value) * factor));

//     return rgbToHex(lighterShade);
//   };

//   let maxContrastColor = baseColor;
//   let maxContrastRatio = getContrastRatio(hexToRgb(baseColor), [0, 0, 0]); // Initial contrast with black

//   // Generate a range of lighter colors with different factors
//   for (let i = 1; i <= 5; i++) {
//     const lighterColor = generateLighterColor(baseColor, 0.1 * i);
//     const contrastRatio = getContrastRatio(hexToRgb(lighterColor), [0, 0, 0]); // Contrast with black

//     // Update max contrast color if a higher contrast is found
//     if (contrastRatio > maxContrastRatio) {
//       maxContrastRatio = contrastRatio;
//       maxContrastColor = lighterColor;
//     }
//   }

//   return maxContrastColor;
// };


export const goToCopilot = ()=> {
  const encryptedToken = encryptString(window.localStorage.getItem('token'));
  const encodedURIString = encodeURIComponent(encryptedToken);
  //window.location.href=`${process.env.REACT_APP_COPILOT_URL}/redirect/${encodedURIString}`;
  window.open(`${process.env.REACT_APP_COPILOT_URL}/redirect?k=${encodedURIString}`, '_blank')
}

// function to merge two arrays of file objects with redundancy remover

export const smartArrayMerge = (mainArray, newArray)=> {
  // const newArray = [
  //   { file: { name: 'file2.txt', size: 200 } }, // Duplicate
  //   { file: { name: 'file3.txt', size: 300 } }, // New
  //   { file: { name: 'file1.txt', size: 150 } }, // Different size, considered unique
  // ];
  
  // Create a Set of unique keys for existing files
  const existingKeys = new Set(
    mainArray.map(item => `${item.name}-${item.size}`)
  );
  
  // Filter out duplicates from the new array
  const filteredNewArray = newArray.filter(item => {
    const key = `${item.name}-${item.size}`;
    return !existingKeys.has(key);
  });
  
  // Merge the arrays
  //const mergedArray = [...mainArray, ...filteredNewArray];

  return filteredNewArray;
}

