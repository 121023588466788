import { getUserRoles } from "../../commonactions/actions";
import { PERMISSION } from "../../constants/constants";
import { updateState } from "../../redux/commonSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { updatePermissionState } from "../../redux/permissionSlice";
import { updateProjectState } from "../../redux/projectSlice";
import { addProjectMemberApi, changeProjectAdminApi, deleteEventNoteApi, deleteEventSongApi, editNotesToEditorApi, editSongTextApi, getAllEventNotesApi, getAllEventSongsApi, removeProjectAdminApi, removeProjectMemberApi, updateProjectApproverRoleApi, updateProjectMemberRoleApi, viewProjectMembersApi } from "../../services/api";
import { checkNewVideo, eventDetails } from "../../services/api";
import { showSnackBar } from "../../utils/commonServices";



//////////////////////////// GET PROJECT MEMBERS ///////////////////////////////////

export const viewProjectMembers = (values, dispatch, navigate, state ) => {
  console.log(state, 'steteeeeeeeeeeeeeeeeeeeeeeeeee')
  const payload = values
  viewProjectMembersApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
       dispatch(updateState({selected_project_members: response?.data?.data?.users}))
       dispatch(updateProjectState({all_project_members:  response?.data?.data?.users}))
       dispatch(updatePermissionState({project_role_id:  response?.data?.data?.users?.find(mem => mem.id === state.commonReducer.user_id)?.role?.id}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
   if(error) {
    console.log(error, 'errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
  }
   // dispatch(setSnackbar({type:'error', flag:true, message: error.response?.data?.message}))
  });
  }
  
  
  //////////////////////////// ADD PROJECT MEMBER ////////////////////////////////////////
  
  export const addProjectMember = (values ,dispatch, navigate, state) => {
    dispatch(updateProjectState({is_loading: true}))
  const payload = values
  addProjectMemberApi(payload) 
  .then((response) => {
    dispatch(updateProjectState({is_loading: false}))
    // console.log(response, 'response')
    if(response?.data?.http_code === 200){
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      viewProjectMembers({event_id: payload?.event_id}, dispatch, navigate, state)
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(updateProjectState({is_loading: false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   //dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    }
  });
  }
  
  
  /////////////////////// UPDATE ROLE OF PROJECT MEMBER //////////////////////////
  
  export const updateProjectMemberRole = (values ,dispatch, navigate, state, setSelectedRole, role) => {
   const viewMember = (permission)=> {
   if (permission[PERMISSION.PROJECT.MEMBER.VIEW]) {
    viewProjectMembers({event_id: values?.event_id}, dispatch, navigate, state)
   } else {
    dispatch(updateProjectState({invite_project_members_popup : false}))
   }
   }

    dispatch(updateProjectState({is_loading: true}))
    const payload = values
  updateProjectMemberRoleApi(payload) 
  .then((response) => {
    dispatch(updateProjectState({is_loading: false}))
    // console.log(response, 'response')
    if(response?.data?.http_code === 200){
      setSelectedRole(role?.name)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      getUserRoles(dispatch, navigate, state.permissionsReducer, {event_id: state.commonReducer?.production_event_id}, viewMember)
     
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(updateProjectState({is_loading: false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    //dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    }
  });
  }
  
  
  /////////////////////////// CHANGE PROJECT ADMIN //////////////////////////////////////
  
  export const changeProjectAdmin = (values ,dispatch, navigate, state ) => {
    const payload = values
    dispatch(updateProjectState({loading_state: 'loading'}))
  changeProjectAdminApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      dispatch(updateProjectState({loading_state: 'success'}))
      viewProjectMembers({event_id: payload?.event_id}, dispatch, navigate, state)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateProjectState({loading_state: 'error'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateProjectState({loading_state: 'error'}))
    }
  });
  }
  
  
  //////////////////////////// REMOVE PROJECT ADMIN ///////////////////////////////////////////

  export const removeProjectAdmin = (values ,dispatch, navigate, state ) => {
    const payload = values
    dispatch(updateProjectState({loading_state: 'loading'}))
  removeProjectAdminApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      viewProjectMembers({event_id: payload?.event_id}, dispatch, navigate, state)
      dispatch(updateProjectState({loading_state: 'success'}))
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      navigate('/dashboard')
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateProjectState({loading_state: 'error'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateProjectState({loading_state: 'error'}))
    }
  });
  }
  

  ////////////////////////////// REMOVE PROJECT MEMBER ////////////////////////////////

  export const removeProjectMember = (values ,dispatch, navigate, state ) => {
    const payload = values
    dispatch(updateProjectState({loading_state: 'loading'}))
removeProjectMemberApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      viewProjectMembers({event_id: payload?.event_id}, dispatch, navigate, state)
      dispatch(updateProjectState({loading_state: 'success'}))
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      dispatch(updateProjectState({loading_state: 'error'}))
    }
  })
  .catch((error) => {
    // console.error('error:', error);
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateProjectState({loading_state: 'error'}))
    }
  });
}


export const checkNewVideoArrival = (payload, dispatch ) => {

    const data = {event_id: payload.event_id, final_video_id: payload.final_video_id}
  
    checkNewVideo(data)
    .then((response) => {
        if (response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data
           if(responseData?.final_video_changed == true) {
            dispatch(updateState({new_video: true}))
            getEventDetails({id: payload.event_id}, dispatch);
         // dispatch(updateState({new_video: true, under_revision:false, selected_event: {...commonReducer.selected_event, status_type: "Review", status_type_order: 4}}))
           } else {
            dispatch(updateState({new_video: false}))
           }
            }
    })
    .catch((error) => {
    
     if (error) {
        const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
       // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
      }
    });
    }

    export const getEventDetails = (payload, dispatch ) => {

     // const data = {id: payload.id}
    
      eventDetails(payload)
      .then((response) => {
          if (response) {
              const responseObject = response?.data;
              const responseData = responseObject?.data
             // console.log("event detail", responseData)
              dispatch(updateState({event_details: responseData}))
              }
      })
      .catch((error) => {
      
       if (error) {
          const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
         // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
        }
      });
      }
  

      //////////////////////////// event Notes /////////////////////////////////////


      export const getAllEventNotes = (values, dispatch, navigate, state ) => {
        const payload = values
        getAllEventNotesApi(payload) 
        .then((response) => {
          if(response?.data?.http_code === 200){
            if(Array.isArray(response?.data?.data?.event_notes)){
              dispatch(updateProjectState({ uploaded_event_notes: response.data.data.event_notes, }));
            }
            
          }else {
              dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
          }
        })
        .catch((error) => {
         if(error) {
          const errorObject = error?.response?.data;
          showSnackBar(dispatch, errorObject);
        }
        });
        }



        export const updateNotesToEditor = (values, dispatch, callback ) => {
          dispatch(updateProjectState({is_loading: true}))
          const payload = values
          editNotesToEditorApi(payload) 
          .then((response) => {
            dispatch(updateProjectState({is_loading: false}))
            if(response?.data?.http_code === 200){
              getEventDetails({id: payload?.id}, dispatch)
              callback()
              
            }else {
                dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            }
          })
          .catch((error) => {
           dispatch(updateProjectState({is_loading: false}))
           if(error) {
            const errorObject = error?.response?.data;
            showSnackBar(dispatch, errorObject);
          }
          });
          }


//////////// delete note to editor ///////////////////////
        
  export const deleteEventNote = (values ,dispatch, callback) => {
    console.log('here')
  dispatch(updateProjectState({ delete_note_loading:true}))
  const payload = values
  deleteEventNoteApi({id: payload?.file_id}) 
  .then((response) => {
    dispatch(updateProjectState({ delete_note_loading:false}))
    if(response?.data?.http_code === 200){
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      getAllEventNotes({event_id: payload?.event_id} ,dispatch)
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(updateProjectState({ delete_note_loading:false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }


  /////////////////////////event songs///////////////////////////////
  export const getAllEventSongs = (values, dispatch, callback ) => {
    const payload = values
    getAllEventSongsApi(payload) 
    .then((response) => {
      if(response?.data?.http_code === 200){
        if(Array.isArray(response?.data?.data?.event_songs)){
          dispatch(updateProjectState({ uploaded_songs: response.data.data.event_songs, }));
          callback && callback()
        }
        
      }else {
          dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      }
    })
    .catch((error) => {
     if(error) {
      const errorObject = error?.response?.data;
      showSnackBar(dispatch, errorObject);
    }
    });
    }


    export const updateEventSongText = (values, dispatch, callback ) => {
      dispatch(updateProjectState({is_loading: true}))
      const payload = values
      editSongTextApi(payload) 
      .then((response) => {
        dispatch(updateProjectState({is_loading: false}))
        if(response?.data?.http_code === 200){
          console.log(payload.id, 'update event song text....')
          getEventDetails({id: payload?.id}, dispatch)
          callback && callback()
          
        }else {
            dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
      })
      .catch((error) => {
       dispatch(updateProjectState({is_loading: false}))
       if(error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject);
      }
      });
      }


    export const deleteEventSong = (values ,dispatch, callback) => {
      console.log('here')
    dispatch(updateProjectState({ delete_song_loading:true}))
    const payload = values
    deleteEventSongApi({id: payload?.file_id}) 
    .then((response) => {
      dispatch(updateProjectState({ delete_song_loading:false}))
      if(response?.data?.http_code === 200){
        dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
        getAllEventSongs({event_id: payload?.event_id} ,dispatch)
      }else {
          dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      }
    })
    .catch((error) => {
      dispatch(updateProjectState({ delete_song_loading:false}))
      if(error) {
      const errorObject = error?.response?.data;
      showSnackBar(dispatch, errorObject);
      }
    });
    }

    export const updateProjectApproverRole = (values ,dispatch , navigate, state) => {
      
      const payload = values
      dispatch(updateProjectState({is_loading: true}))
    updateProjectApproverRoleApi(payload) 
    .then((response) => {
      if(response?.data?.http_code === 200){

        viewProjectMembers({event_id: state?.commonReducer?.production_event_id},dispatch, navigate, {commonReducer: state?.commonReducer})
      
        getEventDetails({id: state?.commonReducer?.production_event_id}, dispatch)
        // getUserRoles(dispatch, navigate, state?.permissionsReducer)
        dispatch(updateProjectState({is_loading: false}))
        // setSelectedRole(role?.name)
        dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateProjectState({is_loading: false}))
      }
    })
    .catch((error) => {
      if(error) {
      const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject);
      }
      dispatch(updateProjectState({is_loading: false}))
    });
    }