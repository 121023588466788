import { Box, Button, Typography } from "@mui/material"
import CustomButton from "../../../components/CustomButton"
// import { customTheme as theme } from "../../../theme/customTheme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import CustomCard from '../../events/components/CustomCard'
import { updateReviewState } from "../../../redux/reviewSlice"
import SendChangeRequestPopup from "./SendChangeRequestPopup"
import AcceptVideoPopup from "./AcceptVideoPopup"
import useRevisionRequests from "../hooks/useRevisionRequests"
import useAcceptFinalVideo from "../hooks/useAcceptFinalVideo"
import useEventList from "../../dashboard/hooks/useEventList"
import usePermissions from "../../../hooks/usePermissions"
import { PERMISSION } from "../../../constants/constants"
import { getEventDetails } from "../../events/actions"
import useCustomTheme from "../../../theme/useCustomTheme"
import CustomTooltip from "../../../components/CustomTooltip"
import QRCode from 'qrcode'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useRevisionActions from "../useRevisionActions"

const SubmitChangeRequestCard = (props) => {
   // const {refetchDetails} = props
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const reviewEventReducer = useSelector((state)=> state.reviewEventReducer);
    const [acceptFinalVideoMutate , acceptFinalVideoStatus] = useAcceptFinalVideo()
    const [submitRevision, submitRevisionStatus] = useRevisionRequests('submit-revision')
    const [theme ] = useCustomTheme()
   
    const dispatch = useDispatch()
    const [hasPermission] = usePermissions()
    const [imageUrl, setImageUrl] = useState('')
    const [copyButtonText, setCopyButtonText] = useState("Copy Link");
    const {sendVideoForApproval} = useRevisionActions()
   // const [refetchEvents] = useEventList();


     
  
    const videoAcceptHandler =  ()=>{
        dispatch(updateReviewState({open_video_accept_popup :true}))
    }

    useEffect(()=> {
        if(acceptFinalVideoStatus == "success") {
           // console.log("refetch")
           getEventDetails({id: commonReducer.production_event_id}, dispatch)
          //refetchDetails()
        }
       }, [acceptFinalVideoStatus])

    const requestChangesHandler =()=>{
      // console.log('Hello changes in send final revision')
      // console.log(!hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.SEND))
      // console.log(commonReducer.event_details.revision_left < 1)
      // console.log(!hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.SEND) ?? commonReducer.event_details.revision_left < 1)
        if(commonReducer.event_details.revision_left > 0 || commonReducer.event_details.unlimited_revision) {
            dispatch(updateReviewState({open_request_changes_popup: true}))
        }
        
    }

    const generateQRCode = async ()=>{
      try {
        const response = await QRCode.toDataURL(`${window.location.host}/guestreview?c=${commonReducer?.event_details?.collab_code}`)
        setImageUrl(response)
      } catch (error) {
       
      }
    }
    const copyClickHandler = () => {
      setCopyButtonText("Copied!")
      navigator.clipboard.writeText(`${window.location.host}/guestreview?c=${commonReducer?.event_details?.collab_code}`)
      setTimeout(() => {
        setCopyButtonText("Copy Link")
      }, 1500)
    }
  
    useEffect(() => {
      generateQRCode() 
    }, [commonReducer.production_event_id, commonReducer?.event_details ])

    const handleImageDownload =()=>{
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = `${commonReducer?.event_details?.name}`;
      link.click();
    }


    const sendForApprovalHandler =  ()=>{
      const payload = {
        final_video_id: commonReducer?.event_details?.final_video_id
      }
      sendVideoForApproval(payload)
  }

    return (
        <CustomCard  style={{height:'100%', }} >
        <Box sx={{display:'flex', height:'100%', width:'100%'}}>
    
            <Box  gap={{xs:2,md:4, lg:5}} sx={{display:'flex',  paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' , justifyContent:'flex-start', alignItems:'center', width:'60%'}}>

                <Box gap={1} className='flex col' sx={{justifyContent:'space-around'}}>
                    <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600, letterSpacing:0, fontFamily:'Rubik'}}>Invite Reviewer</Typography>

                            
                    <CustomButton 
                      btnText={copyButtonText}
                      type='button'
                      onClick={copyClickHandler}
                      color='secondary'
                      variant="contained" 
                      disabled={ !hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.CREATE)}
                      style={{
                        borderRadius: '5px',
                        border: "none",
                        textTransform:'none',
                        // backgroundColor:theme.palette.secondary.main
                      }}
                      sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'},  height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"36px"},fontWeight:600, width:{xs:'70px',sm:'80px',md:'90px',lg:'100px',xl:'123px'}, fontFamily:'Rubik', }}
                    />
                </Box>
             
               {hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.CREATE) && <Box>
                    <CustomTooltip title = "Download QR" >
                      <Box className='flex j-center a-center' sx={{backgroundColor:'white',  boxShadow:'0px 5px 15px #00000029', borderRadius:'10px',height:{xs:'70px',sm:'70px',md:'80px',lg:'100px',xl:'137px'}, width:{xs:'70px',sm:'70px',md:'80px',lg:'100px',xl:'137px'},}} >
                      <Box onClick={handleImageDownload} sx={{height:{xs:'60px',sm:'60px',md:'70px',lg:'90px',xl:'127px'}, width:{xs:'60px',sm:'60px',md:'70px',lg:'90px',xl:'127px'}, cursor:'pointer',}}>
                        {imageUrl ? (  <img src={imageUrl} alt='Guest upload url' height='100%' width='100%' />  ): null}
                      </Box>   
                      </Box>     
                    </CustomTooltip>
                </Box>}
             

{/*    
    
                {
                  commonReducer.event_details.revision_left < 1 ? 
                  <Typography sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>You have already requested maximum allowed revisions. Please contact Lucihub sales for more revisions </Typography>
                      :<>
                        <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600, letterSpacing:0, fontFamily:'Rubik'}}>Submit your revisions.</Typography>
                        <Typography sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>Document all of you and your team's revisions in the log below. Once you’ve compiled all your notes, click <span style={{fontFamily:'Rubik', fontWeight:600}}>Send Final Revisions</span>.<br/> <span style={{fontFamily:'Rubik', fontWeight:600}}>Note:</span> You can only submit your revisions (<span style={{fontFamily:'Rubik', fontWeight:600, color:'#CE0101'}}>{commonReducer.event_details.revision_left}</span>) times. Use it wisely!</Typography>
                      </>
                } */}
           
            
            </Box>
    
            <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', height:'100%' ,width:'40%',  flexDirection:'column'}}>    
            <Box className='flex a-center' gap={0.5}>
              <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600, letterSpacing:0, fontFamily:'Rubik'}}>Submit your revisions</Typography>     
              <CustomTooltip title={<Typography sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>
                  Document all of you and your team's revisions in the log below. Once you’ve compiled all your notes, click 
                  <span style={{fontFamily:'Rubik', marginLeft: 2, fontWeight:600}}>Send Final Revisions</span>.
                  <br/> 
                  {
                    !commonReducer.event_details?.unlimited_revision && <>
                       <span style={{fontFamily:'Rubik', fontWeight:600}}>Note:</span> 
                  You can only submit your revisions (
                    <span style={{fontFamily:'Rubik', fontWeight:600, color:'#CE0101'}}>
                      {commonReducer.event_details.revision_left}</span>) times. Use it wisely!
                    </>
                  }
                 
                    </Typography>} placement = "right" >
                <InfoOutlinedIcon  sx={{fontSize:{xs:'12px !important',lg:'16px !important', xl:'20px !important'}, cursor:'pointer'}}/>
              </CustomTooltip>
            </Box>
           
              <Box className='flex col' sx={{alignItems:'center', justifyContent:'center'}}>
          
                {//hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.SEND) && 
                
                
                <CustomButton 
                  btnText='Send Final Revisions'
                  disabled = {!hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.SEND) || (commonReducer.event_details.revision_left < 1 && !commonReducer.event_details?.unlimited_revision)}
                  // disabled={true}
                  onClick={requestChangesHandler}    
                  type='button'
                  color='secondary'
                  variant='contained'
                  style={{
                  borderRadius: '5px',
                  border: "none",          
                  textTransform:'none',
                  // backgroundColor:theme.palette.secondary.main
                  }}
                  sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'165px',sm:'165px',md:'180px',lg:'200px',xl:'275px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                />}
              {!commonReducer?.event_details?.can_approve ? <Button 
                variant="text"
                disabled={commonReducer?.event_details?.sent_for_approval}
                onClick={sendForApprovalHandler}
                sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                    Or Send for Approval
                </Button> : hasPermission(PERMISSION.PROJECT.REVISION.ACCEPT_VIDEO) && <Button 
                variant="text"
                onClick={videoAcceptHandler}
                disabled = {!hasPermission(PERMISSION.PROJECT.REVISION.ACCEPT_VIDEO)}
                sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                    Or Accept Video
                </Button>}
                </Box>
             
            </Box>
            </Box>
            {reviewEventReducer.open_request_changes_popup && <SendChangeRequestPopup/>}

            { reviewEventReducer.open_video_accept_popup && <AcceptVideoPopup open={reviewEventReducer.open_video_accept_popup} acceptRequest = {acceptFinalVideoMutate}  status = {acceptFinalVideoStatus == "loading"}/>}
        </CustomCard>
      )
}

export default SubmitChangeRequestCard